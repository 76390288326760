import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import prosoape1 from '../content/themes/Personalized/prosoape1.jpg';
import prosoape2 from '../content/themes/Personalized/prosoape2.jpg';
import prosoape3 from '../content/themes/Personalized/prosoape3.jpg';
import prosoape4 from '../content/themes/Personalized/prosoape4.jpg';
import cani1 from '../content/themes/Personalized/cani1.jpg';
import cani2 from '../content/themes/Personalized/cani2.jpg';
import cani3 from '../content/themes/Personalized/cani3.jpg';
import { useEffect, useState, useRef } from "react";
import bg_video from '../content/background_video.mp4';
import './NavBarRo.css';
import '../index.css';
import '../App.css';

const Napkins = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return ( 

        <div className="Personalized">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4" />
        
            <div className="box" style={{top: '150px'}}>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Produse Personalizate
                    </p>

                    <h3 style={{color:"antiquewhite",
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Pe lângă colecția noastră elegantă de produse decorative, vă prezentăm și diferite produse pentru orice ocazie, pe care chiar clienții noștri pot să personalizeze pentru a putea oferi cadouri cât posibil de unice.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Avem două tipuri de produse personalizabile pe care le puteți oferi celor dragi: prosoape și căni personalizate.
                    </h3>

                </div>

                <div className="prosoape_photos">
                    <img className="home_photos" src={prosoape3}/>
                </div>

                <hr className="break"/>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Prosoape Personalizate
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Prosoapele noastre sunt una dintre cele mai populare și îndrăgite produse pe care le putem oferi clienților noștri. Acestea sunt personalizabile cu un nume sau mesaj unic pe prosoape și cu ațe, care pot avea una dintre o multitudine de culori disponibile. Prosoapele noastre sunt din bumbac 100%, cu o mărime de 140 x 70 cm, și pot fi cumpărate din stoc sau la comandă.
                    </h3>
                    
                </div>

                {/* <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img src={photo_1} style={{width: 900}}/>
                        <img src={photo_2} style={{width: 900}}/>
                        <img src={photo_3} style={{width: 900}}/>
                        <img src={photo_4} style={{width: 450}}/>
                        <img src={photo_5} style={{width: 900}}/>
                        <img src={photo_6} style={{width: 900}}/>
                        <img src={photo_7} style={{width: 900}}/>
                        <img src={photo_8} style={{width: 450}}/>
                        images.map(image => <img src={image.url} alt={image.author} />)
                    </Carousel>
                </div> */}

                <div className="prosoape_photos">
                    <img className="home_photos" src={prosoape4}/>
                </div>

                <hr className="break"/>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Căni Personalizate
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Un alt produs disponibil cu mesaje inedite sunt cănile personalizate. Cănile noastre, care sunt sublimate cu nume sau mesaje, pot fi găsite în diferite culori și modele.
                    </h3>

                </div>

                {/* <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img src={photo_1} style={{width: 900}}/>
                        <img src={photo_2} style={{width: 900}}/>
                        <img src={photo_3} style={{width: 900}}/>
                        <img src={photo_4} style={{width: 450}}/>
                        <img src={photo_5} style={{width: 900}}/>
                        <img src={photo_6} style={{width: 900}}/>
                        <img src={photo_7} style={{width: 900}}/>
                        <img src={photo_8} style={{width: 450}}/>
                        images.map(image => <img src={image.url} alt={image.author} />)
                    </Carousel>
                </div> */}

                <div className="prosoape_photos">
                    <img className="home_photos" src={cani1}/>
                </div>

                <p style={{marginBottom: 40, marginTop: 40, color: '#171717'}}>.</p>

            </div>

            <div className="footer" style={{
                marginTop:250,
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>

     );
}
 
export default Napkins;