import { Link } from 'react-router-dom';
import '../index.css';
import '../App.css';
import './NavBarEn.css'
import logo from '../interio_logo.png';
import fb from '../facebook.png';
import insta from '../instagram.png';
import rom from '../romania.png';
import hun from '../hungary.png';
import eng from '../english.png';
import { ListOutline } from 'react-ionicons';
import { MenuOutline } from 'react-ionicons';
import { CloseOutline } from 'react-ionicons';
import { useEffect, useState, useRef } from "react";
import { NavLink } from 'react-router-dom';

const NavBar = () => {

    const [activeNav, setActiveNav] = useState("links");
    const [closer, setCloserVisibility] = useState("close_navbar");
    const [toggler, setTogglerVisibility] = useState("toggle_navbar");
    
    const activeToggler = () => {
        // activeNav === 'links' 
        // ? setActiveNav('links active') 
        // : setActiveNav('links')

        if(activeNav === 'links'){
            setActiveNav('links active');
            setTogglerVisibility('close_navbar');
            setCloserVisibility('toggle_navbar');
        }
        else{
            setActiveNav('links');
            setTogglerVisibility('toggle_navbar');
            setCloserVisibility('close_navbar');
        }

    }

    return ( 
        <nav className="navBar">

            <Link to="/ro">
                <img src={logo} className="nav_logo" alt="logo" />
            </Link>

            <div className={activeNav}> 
                <NavLink to="/en" activeStyle={{color: "#e7af21"}}>Home</NavLink>
                <NavLink to="/about/en" activeStyle={{color: "#e7af21"}}>About Us</NavLink>
                <NavLink to="/products/en" activeStyle={{color: "#e7af21"}}>Our Products</NavLink>
                <NavLink to="/personalized/en" activeStyle={{color: "#e7af21"}}>Personalized Products</NavLink>
                <NavLink to="/decorations/en" activeStyle={{color: "#e7af21"}}>Self-Made Decorations</NavLink>
                <div className="icons" style={{backgroundColor:"#000000"}}>

                    <div className="socials" style={{backgroundColor:"#000000"}}>
                        <a href={"https://www.facebook.com/MagazinulInterio/?locale=ro_RO"} >
                            <img src={fb} className="App-socials" alt="fb" style={{backgroundColor:"#000000"}}/>
                        </a>
                        <a href={"https://www.instagram.com/interiohomedecor/"}>
                            <img src={insta} className="App-socials insta" alt="insta" style={{backgroundColor:"#000000"}}/>
                        </a>
                    </div>

                    <div className="languages" style={{backgroundColor:"#000000"}}>
                        <Link to="/ro" >
                            <img src={rom} className="language" alt="ro" style={{backgroundColor:"#000000"}} onClick={() => activeToggler()}/>
                        </Link>
                        <Link to="/hu" >
                            <img src={hun} className="language" alt="hu" style={{backgroundColor:"#000000"}} onClick={() => activeToggler()}/>
                        </Link>
                        <Link to="/en" >
                            <img src={eng} className="language" alt="en" style={{backgroundColor:"#000000"}} onClick={() => activeToggler()}/>
                        </Link>
                    </div>

                </div>
            </div>

            <div className={toggler}>

                <MenuOutline
                style={{
                    backgroundColor: "#000000",
                    color: "#000000",
                    hidden: "true",
                    display: "flex"
                }}
                className="close_toggle"
                title={'close'}
                height="40px"
                width="40px"
                onClick={() => activeToggler()}
                />

            </div>

            <div className={closer}>

                <CloseOutline
                style={{
                    backgroundColor: "#000000",
                    color: "#000000",
                    hidden: "true",
                    display: "flex"
                }}
                className="menu_toggle"
                title={'menu'}
                height="40px"
                width="40px"
                onClick={() => activeToggler()}
                />

            </div>

        </nav>
     );
}
 
export default NavBar;