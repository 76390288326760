import React from 'react';
import './index.css';
import './App.css';
import './card_wrapper.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import photo_1 from './content/uzlet1.jpg';
import photo_2 from './content/uzlet2.jpg';
import photo_3 from './content/uzlet3.jpg';
import photo_4 from './content/uzlet4.jpg';
import photo_5 from './content/uzlet5.jpg';
import photo_6 from './content/uzlet6.jpg';
import photo_7 from './content/uzlet7.jpg';
import photo_8 from './content/uzlet8.jpg';

const Modal = ({open, photo, onClose}) => {
  
if(!open || photo===null) return (<div />);
  return (
    <div className="overlay" onClick={onClose}>
      <div className="mini_modalContainer" onClick={(e) => {
        e.stopPropagation();
      }}>
        <p className="mini_closeBtn" onClick={onClose}> X </p>
        <img src={require("./content/" + photo)} className="deco_photo"/>
      </div>
    </div>
  )
}

export default Modal
