import React from 'react';
import './index.css';
import './App.css';
import './card_wrapper.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import photo_1 from './content/uzlet1.jpg';
import photo_2 from './content/uzlet2.jpg';
import photo_3 from './content/uzlet3.jpg';
import photo_4 from './content/uzlet4.jpg';
import photo_5 from './content/uzlet5.jpg';
import photo_6 from './content/uzlet6.jpg';
import photo_7 from './content/uzlet7.jpg';
import photo_8 from './content/uzlet8.jpg';

const Modal = ({open, photos, onClose}) => {
  
if(!open || photos.length===0) return (<div />);
  return (
    <div className="overlay" onClick={onClose}>
      <div className="modalContainer" onClick={(e) => {
        e.stopPropagation();
      }}>
        <p className="closeBtn" onClick={onClose}> X </p>
        {/* <img src={photo_1} style={{width: 900}}/> */}
        <Carousel className='carousel' autoPlay showStatus={true} showArrows={true} infiniteLoop thumbWidth={55} axis='horizontal' dynamicHeight={true} style={{zIndex: '50', display: 'flex'}}>
            {/* <div><img src={photo_1} style={{width: '100'}}/></div>
            <div><img src={photo_2} style={{width: '100'}}/></div>
            <div><img src={photo_3} style={{width: '100'}}/></div>
            <div><img src={photo_5} style={{width: '100'}}/></div>
            <div><img src={photo_6} style={{width: '100'}}/></div>
            <div><img src={photo_7} style={{width: '100'}}/></div> */}
            { photos.map((image) => (<div><img src={require("./content/" + image)} style={{width: '100'}} className="theme_photo"/></div>)) }
            {/* { photos.map((image) => (console.log(image))) } */}
        </Carousel>
      </div>
    </div>
  )
}

export default Modal
