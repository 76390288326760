import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import photo_1 from '../content/uzlet1.jpg';
import photo_2 from '../content/uzlet2.jpg';
import photo_3 from '../content/uzlet3.jpg';
import photo_4 from '../content/uzlet4.jpg';
import photo_5 from '../content/uzlet5.jpg';
import photo_6 from '../content/uzlet6.jpg';
import photo_7 from '../content/uzlet7.jpg';
import photo_8 from '../content/uzlet8.jpg';
import bg_video from '../content/background_video.mp4';
import { useEffect, useState, useRef } from "react";
import './NavBarHu.css';
import '../index.css';
import '../App.css';
import '../card_wrapper.css';
import Modal from '../Modal';
import Collection from '../content/collections.json';

const Products = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [photos, setPhotos] = useState([photo_1, photo_2, photo_3]);

    const [openModal, setOpenModal] = useState(false);
    const [currentPhotos, setCurrentPhotos] = useState([]);

    const toggleModal = (photos) => {
        if(openModal === false){
            {console.log("pula", photos)}
            setOpenModal(true);
            setCurrentPhotos(photos);
        }
        else {
            setOpenModal(false);
            setCurrentPhotos([]);
        }
    }

    return ( 

        <div className="Products">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4"/>
        
            <div className="box" style={{top: '150px'}}>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Termékeink
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom: 20,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; Lehetősége van széles választású termék-gyűjteményeinkből válogatni: lakberendezéshez festmények, faliórák, fali dekorációk, vázák, lámpák, tárolódobozok, valamint konyhai termékek és kiegészítők, például különleges és tündöklő evőeszköz gyűjtemények, kávé- és teáskészletek, kávéfőzők, tálcák; ajándéktárgyak, képkeretek, ékszertartók, ajándékok férfiaknak és még sok más.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom: 60,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; Klikkeljen (vagy érintse meg a telefon képernyőjén) az egyik képre az alábbiak közül hogy megtekintsen egyet a számos dekorációs gyűjteményeinkből, mint: a húsvéti szezonbeli gyűjteményünk , ezüst termékeink, levendulás és rózsás termékeink, természeti témás díszeink, arany színű termékeink, konyhai és asztali termékeink, képrámák és fali órák, díszes szalvétáink, parfümös és illatos termékeink, férfi ajándékaink és borok, valamint vázáink és dísznövényeink.
                    </h3>

                </div>

                <div className="cards1">
                    {Collection.map((collection, index) => {
                        if( index >= 0 && index < 4){
                        return(
                            <div key={index} className="card-wrapper">
                                <div className="card">
                                    <div className="card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="card-image_photo" src={require("../content/" + collection.cover)} onClick={() => toggleModal(collection.photos)}/>
                                    </div>
                                    <div className="details">
                                        <h2>{collection.name}
                                        {/* <br />
                                        <span className="description">{collection.desc}</span> */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards2">
                    {Collection.map((collection, index) => {
                        if( index >= 4 && index < 8){
                        return(
                            <div key={index} className="card-wrapper">
                                <div className="card">
                                    <div className="card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="card-image_photo" src={require("../content/" + collection.cover)} onClick={() => toggleModal(collection.photos)}/>
                                    </div>
                                    <div className="details">
                                        <h2>{collection.name}
                                        {/* <br />
                                        <span className="description">{collection.desc}</span> */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 8 && index < 12){
                        return(
                            <div key={index} className="card-wrapper">
                                <div className="card">
                                    <div className="card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="card-image_photo" src={require("../content/" + collection.cover)} onClick={() => toggleModal(collection.photos)}/>
                                    </div>
                                    <div className="details">
                                        <h2>{collection.name}
                                        {/* <br />
                                        <span className="description">{collection.desc}</span> */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>

                <Modal open={openModal} photos={currentPhotos} onClose={() => setOpenModal(false)}/>

            </div>

            <div className="footer" style={{
                marginTop:250,
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>

     );
}
 
export default Products;