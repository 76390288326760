import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import photo_1 from '../content/uzlet1.jpg';
import photo_2 from '../content/uzlet2.jpg';
import photo_3 from '../content/uzlet3.jpg';
import photo_4 from '../content/uzlet4.jpg';
import photo_5 from '../content/uzlet5.jpg';
import photo_6 from '../content/uzlet6.jpg';
import photo_7 from '../content/uzlet7.jpg';
import photo_8 from '../content/uzlet8.jpg';
import { useEffect, useState, useRef } from "react";
import bg_video from '../content/background_video.mp4';
import './NavBarRo.css';
import '../index.css';
import '../App.css';
import ModalDeco from '../ModalDeco';
import Collection from '../content/decorations_collection.json';

const News = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [openModal, setOpenModal] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState("");

    const setOpenModalTest = () => {
        setOpenModal(false);
    }

    const toggleModal = (photo) => {
        if(openModal === false){
            setOpenModal(true);
            setCurrentPhoto(photo);
        }
        else {
            setOpenModal(false);
            setCurrentPhoto("");
        }
    }

    return ( 

        <div className="Personalized">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4" />
        
            <div className="box" style={{top: '150px'}}>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Decorațiuni Făcute De Noi
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom: 15,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; Una dintre cele mai unice produse care le oferăm clienților noștri dragi sunt aranjamente decorative făcute de noi din flori de mătase sau uscate deosebite. Aceste ornamente vor aduce zâmbete și clipe pline de bucurii, care pot fi oferite pentru orice ocazie.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom: 15,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; Cu o voință de a prezenta multe aranjamente inedite și frumoase, vă oferim o colecție sofisticate și unice de aranjamente florale elegante.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom: 60,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Da-ți click (sau apăsați pe ecranul telefonului) pe una dintre decorațiunile de mai jos pentru a mări imaginea.
                    </h3>
                    
                </div>

                <div className="cards1">
                    {Collection.map((collection, index) => {
                        if( index >= 0 && index < 6){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards2">
                    {Collection.map((collection, index) => {
                        if( index >= 6 && index < 12){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 12 && index < 18){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 18 && index < 24){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 24 && index < 30){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 30 && index < 36){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 36 && index < 42){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 42 && index < 48){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 48 && index < 54){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 54 && index < 60){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 60 && index < 66){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 66 && index < 72){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards_last">
                    {Collection.map((collection, index) => {
                        if( index >= 72 && index < 78){
                        return(
                            <div key={index} className="mini_card-wrapper">
                                <div className="mini_card_last">
                                    <div className="mini_card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="mini_card-image_photo_last" src={require("../content/" + collection.photo)} onClick={() => toggleModal(collection.photo)}/>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>

                <ModalDeco open={openModal} photo={currentPhoto} onClose={() => setOpenModal(false)}/>

            </div>

            <div className="footer" style={{
                marginTop:250,
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>

     );
}
 
export default News;