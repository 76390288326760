import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import photo_1 from '../content/uzlet1.jpg';
import photo_2 from '../content/uzlet2.jpg';
import photo_3 from '../content/uzlet3.jpg';
import photo_4 from '../content/uzlet4.jpg';
import photo_5 from '../content/uzlet5.jpg';
import photo_6 from '../content/uzlet6.jpg';
import photo_7 from '../content/uzlet7.jpg';
import photo_8 from '../content/uzlet8.jpg';
import luxor1 from '../content/themes/Luxor/luxor1.jpg';
import luxor2 from '../content/themes/Luxor/luxor2.jpg';
import luxor3 from '../content/themes/Luxor/luxor3.jpg';
import luxor4 from '../content/themes/Luxor/luxor4.jpg';
import luxor5 from '../content/themes/Luxor/luxor5.jpg';
import luxor6 from '../content/themes/Luxor/luxor6.jpg';
import luxor7 from '../content/themes/Luxor/luxor7.jpg';
import { useEffect, useState, useRef } from "react";
import bg_video from '../content/background_video.mp4';
import './NavBarEn.css';
import '../index.css';
import '../App.css';

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [photos, setPhotos] = useState([photo_1, photo_2, photo_3]);

    return ( 
        // <div className="not-found">
        //     <h2>About Us</h2>
        //     <h2>The page is unavailable</h2>
        //     <Link to="/">Back To Home Page</Link>
        // </div>

        <div className="About">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4" />
        
            <div className="box" style={{top: '150px'}}>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    About Us
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom: 10,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp;&nbsp; With over 30 years of activity in the field of trade, we are waiting for you in two stores in Târgu Mureș, in the city center and in the Luxor Galleries. With the accumulated experience, we constantly strive to discover new supply markets, to ensure a wide range of products, and at the same time, superior quality at the best price.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom: 100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; We want to inspire customers in decorating their homes and offer unique gifts to their loved ones.
                    Our items are perfect to give as gifts for any occasion, as are our gift cards.
                    We are passionate about interior decorations that offer style, character and personality.
                    </h3>
                </div>

                <hr className="break"/>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p className="about_header" style={{
                            fontStyle : "italic",
                            minHeight : 120,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center',
                            color: 'white'
                        }}>
                    Interio
                    </p>

                </div>

                <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img className="home_photos" src={photo_1}/>
                        <img className="home_photos" src={photo_2}/>
                        <img className="home_photos" src={photo_3}/>
                        {/* <img className="home_photos" src={photo_4}/> */}
                        <img className="home_photos" src={photo_5}/>
                        <img className="home_photos" src={photo_6}/>
                        <img className="home_photos" src={photo_7}/>
                        {/* <img className="home_photos" src={photo_8}/> */}
                        {/* images.map(image => <img src={image.url} alt={image.author} />) */}
                    </Carousel>
                </div>

                <p style={{marginBottom: 40}}></p>

                <div className="map_info">

                    <div className="map_text">
                        <p className="map_text_title" style={{
                                fontStyle : "italic",
                                minHeight : 50,
                                fontSize: 25,
                                // marginTop: '20px',
                                position: 'relative',
                                zIndex: 50
                            }}>
                        Location & Program
                        </p>
                        <h3 style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none',
                                marginBottom: 60}}>
                        Piața Trandafirilor Nr. 33
                        </h3>
                        <h3 style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none',
                                marginBottom: 10}}>
                        Monday - Friday: &nbsp; 9 - 20 <br />
                        </h3>
                        <h3 style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none',
                                marginBottom: 10}}>
                        Saturday: &nbsp; 9 - 18 <br />
                        </h3>
                        <h3 className="map_text_bottom" style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none'}}>
                        Sunday: &nbsp; Closed
                        </h3>
                        {/* <h3 style={{color:"antiquewhite", marginBottom:100,
                                position: 'relative',
                                zIndex: 50,
                                background: 'none'}}>
                        Recomandăm în atenţia comercianţilor de profil, oferta noastră de şerveţele, lumânări de decor, importate şi distribuite de la două mari producători cu renume din Europa.
                        </h3> */}
                    </div>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2744.2311566485814!2d24.556646611521323!3d46.54311396068906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474bb63684940001%3A0x7b66a7130fec144e!2sInterio%20Home-Decor!5e0!3m2!1sen!2sro!4v1709650747869!5m2!1sen!2sro" className="map" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>

                <hr className="break"/>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p className="about_header" style={{
                            fontStyle : "italic",
                            minHeight : 120,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center',
                            color: 'white'
                        }}>
                    Luxor
                    </p>

                </div>

                <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img className="home_photos" src={luxor1}/>
                        <img className="home_photos" src={luxor2}/>
                        <img className="home_photos" src={luxor3}/>
                        <img className="home_photos" src={luxor4}/>
                        <img className="home_photos" src={luxor5}/>
                        {/* <img className="home_photos" src={luxor6} style={{width: 450}}/> */}
                        <img className="home_photos" src={luxor7}/>
                        {/* images.map(image => <img src={image.url} alt={image.author} />) */}
                    </Carousel>
                </div>

                <p style={{marginBottom: 40}}></p>

                <div className="map_info">

                    <div className="map_text">
                        <p className="map_text_title" style={{
                                fontStyle : "italic",
                                minHeight : 50,
                                fontSize: 25,
                                // marginTop: '20px',
                                position: 'relative',
                                zIndex: 50
                            }}>
                        Location & Program
                        </p>
                        <h3 style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none',
                                marginBottom: 60}}>
                        Piața Teatrului Nr. 1, I. floor
                        </h3>
                        <h3 style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none',
                                marginBottom: 10}}>
                        Monday - Friday: &nbsp; 9 - 20 <br />
                        </h3>
                        <h3 style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none',
                                marginBottom: 10}}>
                        Saturday: &nbsp; 9 - 14 <br />
                        </h3>
                        <h3 className="map_text_bottom" style={{color:"antiquewhite",
                                position: 'relative',
                                zIndex: 50,
                                background: 'none'}}>
                        Sunday: &nbsp; Closed
                        </h3>
                        {/* <h3 style={{color:"antiquewhite", marginBottom:100,
                                position: 'relative',
                                zIndex: 50,
                                background: 'none'}}>
                        Recomandăm în atenţia comercianţilor de profil, oferta noastră de şerveţele, lumânări de decor, importate şi distribuite de la două mari producători cu renume din Europa.
                        </h3> */}
                    </div>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2744.100344168436!2d24.560311499999997!3d46.545701799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474bb63415e9d5eb%3A0x3f68e4341d2b8fe6!2sLuxor!5e0!3m2!1sen!2sro!4v1710348208322!5m2!1sen!2sro" className="map_luxor" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>

                <p style={{marginBottom: 40, marginTop: 100, color: '#171717'}}>.</p>

            </div>

            <div className="footer" style={{
                marginTop:250,
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>

     );
}
 
export default AboutUs;