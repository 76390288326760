import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import prosoape1 from '../content/themes/Personalized/prosoape1.jpg';
import prosoape2 from '../content/themes/Personalized/prosoape2.jpg';
import prosoape3 from '../content/themes/Personalized/prosoape3.jpg';
import prosoape4 from '../content/themes/Personalized/prosoape4.jpg';
import cani1 from '../content/themes/Personalized/cani1.jpg';
import cani2 from '../content/themes/Personalized/cani2.jpg';
import cani3 from '../content/themes/Personalized/cani3.jpg';
import { useEffect, useState, useRef } from "react";
import bg_video from '../content/background_video.mp4';
import './NavBarHu.css';
import '../index.css';
import '../App.css';

const Napkins = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return ( 
        <div className="Personalized">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4" />
        
            <div className="box" style={{top: '150px'}}>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Testreszabott Termékeink
                    </p>

                    <h3 style={{color:"antiquewhite",
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Elegáns dekorációs termékeink mellett különleges, vásárlóink által is testreszabható termékeket is kínálunk bármilyen alkalomra, hogy minél egyedibb ajándékokat tudjanak másoknak kínálni.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Kétféle személyre szabható termékünk van, amelyeket szeretteiknek ajándékozhat: személyre szabható törölközők és bögrék.
                    </h3>

                </div>

                <div className="prosoape_photos">
                    <img className="home_photos" src={prosoape3}/>
                </div>

                <hr className="break"/>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Testreszabható Törölközők
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Törölközőink egyik legnépszerűbb és legkedveltebb termékeink, amelyeket vásárlóink ​​számára kínálhatunk. Ezek testreszabhatóak egyedi névvel vagy üzenettel, valamint a törülközők és maga a cérna többféle szín közül választhatók. Törölközőink 100% pamutból vannak, 140 x 70 cm méretűek, valamint raktárról vagy akár rendelésre is megvásárolhatóak.
                    </h3>

                </div>

                {/* <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img src={photo_1} style={{width: 900}}/>
                        <img src={photo_2} style={{width: 900}}/>
                        <img src={photo_3} style={{width: 900}}/>
                        <img src={photo_4} style={{width: 450}}/>
                        <img src={photo_5} style={{width: 900}}/>
                        <img src={photo_6} style={{width: 900}}/>
                        <img src={photo_7} style={{width: 900}}/>
                        <img src={photo_8} style={{width: 450}}/>
                        images.map(image => <img src={image.url} alt={image.author} />)
                    </Carousel>
                </div> */}

                <div className="prosoape_photos">
                    <img className="home_photos" src={prosoape4}/>
                </div>

                <hr className="break"/>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Testreszabható Bögrék
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Egy másik egyedi üzenetekkel ellátható termékünk a személyre szabható bögre. Nevekkel vagy üzenetekkel szublimált bögréink többféle színben és mintában megtalálhatóak.
                    </h3>

                </div>

                {/* <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img src={photo_1} style={{width: 900}}/>
                        <img src={photo_2} style={{width: 900}}/>
                        <img src={photo_3} style={{width: 900}}/>
                        <img src={photo_4} style={{width: 450}}/>
                        <img src={photo_5} style={{width: 900}}/>
                        <img src={photo_6} style={{width: 900}}/>
                        <img src={photo_7} style={{width: 900}}/>
                        <img src={photo_8} style={{width: 450}}/>
                        images.map(image => <img src={image.url} alt={image.author} />)
                    </Carousel>
                </div> */}

                <div className="prosoape_photos">
                    <img className="home_photos" src={cani1}/>
                </div>

                <p style={{marginBottom: 40, marginTop: 40, color: '#171717'}}>.</p>

            </div>

            <div className="footer" style={{
                marginTop:250,
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>
     );
}
 
export default Napkins;