import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import photo_1 from '../content/uzlet1.jpg';
import photo_2 from '../content/uzlet2.jpg';
import photo_3 from '../content/uzlet3.jpg';
import photo_4 from '../content/uzlet4.jpg';
import photo_5 from '../content/uzlet5.jpg';
import photo_6 from '../content/uzlet6.jpg';
import photo_7 from '../content/uzlet7.jpg';
import photo_8 from '../content/uzlet8.jpg';
import bg_video from '../content/background_video.mp4';
import { useEffect, useState, useRef } from "react";
import './NavBarRo.css';
import '../index.css';
import '../App.css';
import '../card_wrapper.css';
import Modal from '../Modal';
import Collection from '../content/collections.json';

const Products = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [photos, setPhotos] = useState([photo_1, photo_2, photo_3]);
    // const [photoProba, setPhotoProba] = useState(Collection.collection1.cover);
    const [fork, setFork] = useState(false);

    // const [collection, setCollection] = useState([
    //     {name: `${Collection.collection1.name}`, "desc": Collection.collection1.desc, "cover": Collection.collection1.cover, "photos": Collection.collection1.photos},
    //     {name: `${Collection.collection2.name}`, desc: Collection.collection2.desc, cover: Collection.collection2.cover, photos: Collection.collection2.photos},
    //     {name: Collection.collection3.name, desc: Collection.collection3.desc, cover: Collection.collection3.cover, photos: Collection.collection3.photos},
    //     {name: Collection.collection4.name, desc: Collection.collection4.desc, cover: Collection.collection4.cover, photos: Collection.collection4.photos},
    //     {name: Collection.collection5.name, desc: Collection.collection5.desc, cover: Collection.collection5.cover, photos: Collection.collection5.photos},
    //     {name: Collection.collection6.name, desc: Collection.collection6.desc, cover: Collection.collection6.cover, photos: Collection.collection6.photos},
    //     {name: Collection.collection7.name, desc: Collection.collection7.desc, cover: Collection.collection7.cover, photos: Collection.collection7.photos},
    //     {name: Collection.collection8.name, desc: Collection.collection8.desc, cover: Collection.collection8.cover, photos: Collection.collection8.photos},
    //     {name: Collection.collection9.name, desc: Collection.collection9.desc, cover: Collection.collection9.cover, photos: Collection.collection9.photos},
    //     {name: Collection.collection10.name, desc: Collection.collection10.desc, cover: Collection.collection10.cover, photos: Collection.collection10.photos},
    //     {name: Collection.collection11.name, desc: Collection.collection11.desc, cover: Collection.collection11.cover, photos: Collection.collection11.photos},
    //     {name: Collection.collection12.name, desc: Collection.collection12.desc, cover: Collection.collection12.cover, photos: Collection.collection12.photos}
    // ]);

    const [openModal, setOpenModal] = useState(false);
    const [currentPhotos, setCurrentPhotos] = useState([]);

    const setOpenModalTest = () => {
        setOpenModal(false);
        console.log("da")
    }

    const toggleModal = (photos) => {
        if(openModal === false){
            setOpenModal(true);
            setCurrentPhotos(photos);
        }
        else {
            setOpenModal(false);
            setCurrentPhotos([]);
        }
    }

    return ( 
        // <div className="not-found">
        //     <h2>About Us</h2>
        //     <h2>The page is unavailable</h2>
        //     <Link to="/">Back To Home Page</Link>
        // </div>

        <div className="Products">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4"/>
        
            <div className="box" style={{top: '150px'}}>

                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}
                    <p style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            marginTop: '20px',
                            position: 'relative',
                            zIndex: 50,
                            textAlign: 'center'
                        }}>
                    Produsele Noastre
                    </p>

                    <h3 style={{color:"antiquewhite", marginBottom: 20,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; Aveţi ocazia de a alege dintr-o colecție de produse decorative: pentru decorarea locuinţei, tablouri, ceasuri de perete, decoruri pentru perete, vaze, veioze, cutii de depozitare, deasemenea articole şi accesorii de bucătărie, cum ar fi servicii de masă, seturi de cafea şi ceai, cafetiere, tăvi; articole de cadouri, rame foto, suporturi pentru bijuterii, cadouri pentru bărbaţi şi multe altele.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom: 60,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    &nbsp; &nbsp; Da-ți click (sau apăsați pe ecranul telefonului) pe una dintre imaginile de mai jos pentru a viziona o colecție din gama noastră extravagantă de cadouri și accesorii, precum: colecția noastră sezonală de paște, decorațiuni argintii, cu lavende, cu trandafiri, decorațiuni bazate pe natură, pe culoarea aurie, decorațiuni de bucătărie și de masă, rame foto și ceasuri, șervețele decorative, produse parfumate, cadouri pentru bărbați și vin, precum și vaze și flori decorative.
                    </h3>
                    {/* <h3 style={{color:"antiquewhite",
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    În magazinul nostru din strada Călăraşilor nr. 3, din Tîrgu Mureş, şi deasemenea în cele două puncte de lucru situate în Galeriile „Luxor” etajul I, un mare sortiment de produse îi aşteaptă pe clienţii noştrii.
                    </h3>
                    <h3 style={{color:"antiquewhite", marginBottom:100,
                            position: 'relative',
                            zIndex: 50,
                            background: 'none'}}>
                    Recomandăm în atenţia comercianţilor de profil, oferta noastră de şerveţele, lumânări de decor, importate şi distribuite de la două mari producători cu renume din Europa.
                    </h3> */}
                </div>

                <div className="cards1">
                    {Collection.map((collection, index) => {
                        if( index >= 0 && index < 4){
                        return(
                            <div key={index} className="card-wrapper">
                                <div className="card">
                                    <div className="card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="card-image_photo" src={require("../content/" + collection.cover)} onClick={() => toggleModal(collection.photos)}/>
                                    </div>
                                    <div className="details">
                                        <h2>{collection.name}
                                        {/* <br />
                                        <span className="description">{collection.desc}</span> */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards2">
                    {Collection.map((collection, index) => {
                        if( index >= 4 && index < 8){
                        return(
                            <div key={index} className="card-wrapper">
                                <div className="card">
                                    <div className="card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="card-image_photo" src={require("../content/" + collection.cover)} onClick={() => toggleModal(collection.photos)}/>
                                    </div>
                                    <div className="details">
                                        <h2>{collection.name}
                                        {/* <br />
                                        <span className="description">{collection.desc}</span> */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>
                <div className="cards3">
                    {Collection.map((collection, index) => {
                        if( index >= 8 && index < 12){
                        return(
                            <div key={index} className="card-wrapper">
                                <div className="card">
                                    <div className="card-image">
                                        {/* <img src={require(collection.cover)} alt="profile one" onClick={() => toggleModal(require(collection.photos))}/> */}
                                        <img className="card-image_photo" src={require("../content/" + collection.cover)} onClick={() => toggleModal(collection.photos)}/>
                                    </div>
                                    <div className="details">
                                        <h2>{collection.name}
                                        {/* <br />
                                        <span className="description">{collection.desc}</span> */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    })}
                </div>

                {/* <div className="cards">

                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={collection[0].cover} alt="profile one" onClick={() => toggleModal(collection[0].photos)}/>
                            </div>
                            <div className="details">
                                <h2>{collection[1].name}
                                <br />
                                <span className="description">{collection[1].desc}</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_2} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 2
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_3} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 3
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_4} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 4
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cards">
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_5} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 5
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_6} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 6
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_7} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 7
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_8} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 8
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="cards">

                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_5} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 5
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_6} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 6
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_7} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 7
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className="card">
                            <div className="card-image">
                                <img src={photo_8} alt="profile one" onClick={() => toggleModal()}/>
                            </div>
                            <div className="details">
                                <h2>Produs 8
                                <br />
                                <span className="description">multe produse frumoase plm</span>
                                </h2>
                            </div>
                        </div>
                    </div>

                </div> */}

                <Modal open={openModal} photos={currentPhotos} onClose={() => setOpenModal(false)}/>

            </div>

            <div className="footer" style={{
                marginTop:250,
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>

     );
}
 
export default Products;