import video_1 from '../content/video1.mp4';
import video_2 from '../content/video2.mp4';
import video_3 from '../content/video3.mp4';
import video_4 from '../content/video4.mp4';
import video_5 from '../content/video5.mp4';
import main1 from '../content/home/photos/main1.jpg';
import main2 from '../content/home/photos/main2.jpg';
import main3 from '../content/home/photos/main3.jpg';
import main4 from '../content/home/photos/main4.jpg';
import main5 from '../content/home/photos/main5.jpg';
import main6 from '../content/home/photos/main6.jpg';
import main7 from '../content/home/photos/main7.jpg';
import main8 from '../content/home/photos/main8.jpg';
import main9 from '../content/home/photos/main9.jpg';
import bg_video from '../content/background_video.mp4';
import home_video from '../content/home/home_video.mp4';
import gold_video from '../content/home/themes/gold_video.mp4';
import kitchen_video from '../content/home/themes/kitchen_video.mp4';
import lavander_video from '../content/home/themes/lavander_video.mp4';
import mens_video from '../content/home/themes/mens_video.mp4';
import napkins_video from '../content/home/themes/napkins_video.mp4';
import paste_video from '../content/home/themes/paste_video.mp4';
import absolvire_video from '../content/home/themes/absolvire_video.mp4';
import rose_video from '../content/home/themes/rose_video.mp4';
import scents_video from '../content/home/themes/scents_video.mp4';
import vases_video from '../content/home/themes/vases_video.mp4';
import silver_video from '../content/home/themes/silver_video.mp4';
import wall_video from '../content/home/themes/wall_video.mp4';
import logo from '../interio_logo.png';
import { useEffect, useState, useRef } from "react";
import './NavBarEn.css';
import '../index.css';
import '../App.css';
import ReactPlayer from "react-player";
// import "bootstrap/dist/css/bootstrap.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from 'react-router-dom';

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    // const [video, setVideo] = useState(video_1);

    const [videos, setVideos] = useState([video_1, video_2, video_3, video_4, video_5]);
    const [currentVideo, setCurrentVideo] = useState(0);

    const changeVideo = () => {
        if(currentVideo === videos.length-1)setCurrentVideo(0);
        else setCurrentVideo(currentVideo+1);
    }

    const nextVideo = () => {
        if(currentVideo === videos.length-1)setCurrentVideo(0);
        else setCurrentVideo(currentVideo+1);
    }

    const previousVideo = () => {
        if(currentVideo === 0)setCurrentVideo(videos.length-1);
        else setCurrentVideo(currentVideo-1);
    }

    return (
        
        <div className="Home">

            <video autoPlay loop className='title_video' muted src={bg_video} type="video/mp4" />

            <div className="title_box">
                
                <video autoPlay loop className='welcome_video' muted src={home_video} type="video/mp4" />

                <div className="title">

                    <h1 className="title_text" style={{
                            fontStyle : "italic",
                            minHeight : 80,
                            fontSize: 25,
                            dsiplay: 'flex',
                            alignItems : 'center',
                            top: '150px',
                            zIndex: '50',
                            position: 'relative',
                            background: 'none',
                        }}>
                    Welcome to the Interio Home Decor official website!
                    </h1>

                </div>

                <img src={logo} className="title_logo" alt="logo" style={{marginBottom: '240px', zIndex: '50', marginTop: '170px'}}/>

            </div>

            <div className="box">

                {/* <div className="home_carousel" style={{display:"flex", alignItems:"center", justifyContent:"center"}}> */}
                    
                    {/* <button onClick={previousVideo} style={{zIndex: "50"}}>previous</button>
                    <video autoPlay className='video_content' muted width="800" src={videos[currentVideo]} type="video/mp4" onEnded={changeVideo} style={{zIndex: "50"}}/>
                    <button onClick={nextVideo} style={{zIndex: "50"}}>next</button> */}

                <div className="photos">
                    <Carousel style={{margin: "60 auto", position: 'relative', zIndex: 50}} autoPlay infiniteLoop showThumbs={false} dynamicHeight={true}>
                        <img className="home_photos" src={main1}/>
                        <img className="home_photos" src={main2}/>
                        <img className="home_photos" src={main3}/>
                        <img className="home_photos" src={main4}/>
                        <img className="home_photos" src={main5}/>
                        <img className="home_photos" src={main6}/>
                        <img className="home_photos" src={main7}/>
                        <img className="home_photos" src={main8}/>
                        <img className="home_photos" src={main9}/>
                    </Carousel>
                </div>

                {/* </div> */}
            
                <div className="text">
                    {/* <h2 style={{minHeight: 150}}></h2> */}

                    <h3 style={{color:"antiquewhite", zIndex: '50', marginBottom: 30,
                            position: 'relative', background: 'none', marginTop: '140px'}}>
                    &nbsp; &nbsp; The Interio store was born from the passion for beauty and the desire to offer unique solutions for home and garden decoration. You have the opportunity to choose from thousands of products from many different well-known manufacturers from all over Europe and beyond!
                    </h3>
                    <h3 style={{color:"antiquewhite", zIndex: '50', marginBottom: 10,
                            position: 'relative', background: 'none', marginBottom: '140px'}}>
                    &nbsp; &nbsp; We offer a wide range of home decor, tableware and gifts in our stores. In addition to a collection in permanent renewal, which gives charm to the home, we provide unique and handmade products from different brands and manufacturers for the realization of ideas, and also offer useful advice in choosing a gift for your loved ones.
                    </h3>
                    {/* <h3 style={{color:"antiquewhite", zIndex: '50',
                            position: 'relative', background: 'none', marginBottom: '50px'}}>
                    &nbsp; &nbsp; You have the opportunity to choose from a collection of decorative products: for home decoration, paintings, wall clocks, wall decorations, vases, lamps, storage boxes, as well as kitchen items and accessories, such as silverware, coffee and tea sets, coffee makers, trays; gift items, photo frames, jewelry holders, gifts for men and more.
                    </h3> */}
                </div>

                <div className="video_carousel">
                    {/* <Carousel>
                        {videoProperties.map((videoObj) => {
                            return (
                                <Carousel.Item key={videoObj.id}>
                                    <ReactPlayer
                                        url={videoObj.src}
                                        pip={true}
                                        controls={false}
                                        playing={true}
                                        autoPlay
                                        muted={true}
                                        loop={true}
                                    />
                                    <Carousel.Caption>
                                        <h3>{videoObj.title}</h3>
                                        <p>{videoObj.credit}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel> */}

                    <div className="product_box">

                        <Link to="/products/en">
                            <Carousel className='video_carousel' autoPlay showStatus={false} showArrows={false} infiniteLoop showThumbs={false} width={'100%'} interval={'4000'}>
                            <div>
                                    <video autoPlay className='carousel_video' muted loop src={absolvire_video} type="video/mp4"/>
                                    <p className="caption">Seasonal - Graduation</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={kitchen_video} type="video/mp4"/>
                                    <p className="caption">Kitchen Decorations</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={gold_video} type="video/mp4"/>
                                    <p className="caption">Golden Collection</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={silver_video} type="video/mp4"/>
                                    <p className="caption">White & Silver Collection</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={lavander_video} type="video/mp4"/>
                                    <p className="caption">Lavander Collection</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={rose_video} type="video/mp4"/>
                                    <p className="caption">Rose Collection</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={wall_video} type="video/mp4"/>
                                    <p className="caption">Wall Clocks & Painted Pictures</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={vases_video} type="video/mp4"/>
                                    <p className="caption">Decorative Vases & Flowers</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={mens_video} type="video/mp4"/>
                                    <p className="caption">Wine & Men's Gifts</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={scents_video} type="video/mp4"/>
                                    <p className="caption">Scented Candles & Perfumes</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                                <div>
                                    <video autoPlay className='carousel_video' muted loop src={napkins_video} type="video/mp4"/>
                                    <p className="caption">Decorative & Seasonal Napkins</p>
                                    <p className="caption_lower" style={{marginTop: "200"}}>Click to check all of our collections!</p>
                                </div>
                            </Carousel>
                        </Link>

                    </div>

                </div>

            </div>

            <div className="footer" style={{
                marginBottom:10
            }}>
                <h5 style={{color:"antiquewhite", zIndex: '50',
                        position: 'relative', background: 'none'}}>
                Copyright © 2014 Interio - HomeDecor
                </h5>
            </div>

        </div>
    );

}

export default Home;