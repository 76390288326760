import AboutUsRo from './components_ro/AboutUsRo';
import AboutUsHu from './components_hu/AboutUsHu';
import AboutUsEn from './components_en/AboutUsEn';
import HomeRo from './components_ro/HomeRo';
import HomeHu from './components_hu/HomeHu';
import HomeEn from './components_en/HomeEn';
import NavBarRo from './components_ro/NavBarRo';
import NavBarHu from './components_hu/NavBarHu';
import NavBarEn from './components_en/NavBarEn';
import ProductsRo from './components_ro/ProductsRo';
import ProductsHu from './components_hu/ProductsHu';
import ProductsEn from './components_en/ProductsEn';
import PersonalizedRo from './components_ro/PersonalizedRo';
import PersonalizedHu from './components_hu/PersonalizedHu';
import PersonalizedEn from './components_en/PersonalizedEn';
import DecosRo from './components_ro/DecosRo';
import DecosHu from './components_hu/DecosHu';
import DecosEn from './components_en/DecosEn';
import NotFound from './NotFound';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';

function App() {

  // const { pathname } = useLocation();

  return (
    <Router>

      <div className="App">
        
        <Switch>
          <Route path="/">
            <NavBarRo />
          </Route>
        </Switch>
        <Switch>
          <Route path="/:pageNo*/ro">
            <NavBarRo />
          </Route>
        </Switch>
        <Switch>
          <Route path="/:pageNo*/hu">
            <NavBarHu />
          </Route>
        </Switch>
        <Switch>
          <Route path="/:pageNo*/en">
            <NavBarEn />
          </Route>
        </Switch>

        <div className="Content">
          <Switch>

            <Route exact path="/">
              <HomeRo />
            </Route>
            <Route exact path="/ro">
              <HomeRo />
            </Route>
            <Route exact path="/hu">
              <HomeHu />
            </Route>
            <Route exact path="/en">
              <HomeEn />
            </Route>

            <Route exact path="/about/ro">
              <AboutUsRo />
            </Route>
            <Route exact path="/about/hu">
              <AboutUsHu />
            </Route>
            <Route exact path="/about/en">
              <AboutUsEn />
            </Route>

            <Route exact path="/products/ro">
              <ProductsRo />
            </Route>
            <Route exact path="/products/hu">
              <ProductsHu />
            </Route>
            <Route exact path="/products/en">
              <ProductsEn />
            </Route>

            <Route exact path="/personalized/ro">
              <PersonalizedRo />
            </Route>
            <Route exact path="/personalized/hu">
              <PersonalizedHu />
            </Route>
            <Route exact path="/personalized/en">
              <PersonalizedEn />
            </Route>

            <Route exact path="/decorations/ro">
              <DecosRo />
            </Route>
            <Route exact path="/decorations/hu">
              <DecosHu />
            </Route>
            <Route exact path="/decorations/en">
              <DecosEn />
            </Route>

            <Route path="*"> {/* "*" => universal catch (ha egyiknek sem felel meg akkor ez) */}
              <NotFound />
            </Route>

          </Switch>
        </div>
      </div>

    </Router>
  );
}

export default App;
